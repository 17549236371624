import { createSlice } from "@reduxjs/toolkit";
const feedbackStateSlice = createSlice({
    name: "feedbackStateStore",
    initialState: {
        feedbackState: undefined,
        hasHeaderLoaded: false,
        activityId: undefined
    },
    reducers: {
        setFeedbackState(state, action) {
            state.feedbackState = action.payload;
        },
        updateFeedbackArgs(state, action) {
            if (state.feedbackState) {
                state.feedbackState.args = action.payload;
            }
        },
        setHeaderFlag(state, action) {
            state.hasHeaderLoaded = action.payload;
        },
        setActivityId(state, action) {
            state.activityId = action.payload;
        }
    }
});
export const feedbackStateSliceActions = feedbackStateSlice.actions;
export default feedbackStateSlice;