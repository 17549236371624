import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";

const setWindowHeight = () => {
  const rootDiv = document.getElementById("root");
  if (rootDiv) {
    rootDiv.style.height = `${window.innerHeight}px`;
  }
};

window.addEventListener('load', setWindowHeight);
window.addEventListener('resize', setWindowHeight);



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);
